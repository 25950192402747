<template>
    <div class="it-app-integration">
      <!-- Hero Section -->
      <section class="hero">
        <div class="hero-content">
          <h1>Integración de Apps de Terceros</h1>
          <p>Conectamos tus sistemas actuales con las mejores aplicaciones externas para optimizar tus procesos empresariales.</p>
        </div>
      </section>
  
      <!-- ¿Por qué integrar apps de terceros? -->
      <section class="why-integration">
        <div class="container">
          <h2>¿Por qué integrar aplicaciones de terceros?</h2>
          <div class="benefits">
            <div class="benefit-card">
              <h3>Mejora de la Productividad</h3>
              <p>Automatiza tareas y mejora la eficiencia operativa al integrar diversas aplicaciones y sistemas en un único flujo de trabajo.</p>
            </div>
            <div class="benefit-card">
              <h3>Escalabilidad y Flexibilidad</h3>
              <p>Las integraciones permiten a tu empresa adaptarse a nuevas necesidades sin reinventar la infraestructura tecnológica existente.</p>
            </div>
            <div class="benefit-card">
              <h3>Optimización de Costos</h3>
              <p>Al integrar apps de terceros, puedes reducir costos operacionales al eliminar redundancias y mejorar la eficiencia de tu infraestructura tecnológica.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Cómo funciona la integración -->
      <section class="how-it-works">
        <div class="container">
          <h2>¿Cómo funciona la integración?</h2>
          <div class="steps">
            <div class="step">
              <h3>1. Análisis y Planificación</h3>
              <p>Realizamos un análisis exhaustivo de las aplicaciones que deseas integrar y el flujo de trabajo de tu empresa para crear una solución a medida.</p>
            </div>
            <div class="step">
              <h3>2. Desarrollo de Conectores</h3>
              <p>Desarrollamos y adaptamos conectores que permiten la comunicación fluida entre las distintas plataformas, respetando tus necesidades específicas.</p>
            </div>
            <div class="step">
              <h3>3. Implementación y Pruebas</h3>
              <p>Implementamos la solución de integración y realizamos pruebas exhaustivas para asegurar que todo funcione correctamente.</p>
            </div>
            <div class="step">
              <h3>4. Monitoreo y Soporte</h3>
              <p>Una vez implementado, ofrecemos monitoreo continuo y soporte para asegurarnos de que la integración esté funcionando correctamente a lo largo del tiempo.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Integraciones Comunes -->
      <section class="common-integrations">
        <div class="container">
          <h2>Integraciones Comunes</h2>
          <div class="integration-cards">
            <div class="integration-card">
              <img src="https://via.placeholder.com/150x100" alt="Integración 1">
              <h3>CRM (Salesforce, HubSpot, Zoho)</h3>
              <p>Integramos plataformas de gestión de relaciones con clientes (CRM) para centralizar y optimizar la interacción con tus clientes.</p>
            </div>
            <div class="integration-card">
              <img src="https://via.placeholder.com/150x100" alt="Integración 2">
              <h3>Sistemas de Pago (PayPal, Stripe)</h3>
              <p>Conectamos tus aplicaciones de pago con tus plataformas para facilitar transacciones y simplificar la gestión de cobros.</p>
            </div>
            <div class="integration-card">
              <img src="https://via.placeholder.com/150x100" alt="Integración 3">
              <h3>Plataformas de Marketing (MailChimp, Marketo)</h3>
              <p>Facilitamos la integración de plataformas de marketing digital para optimizar tus campañas y automatizar tareas repetitivas.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Casos de Éxito -->
      <section class="success-cases">
        <div class="container">
          <h2>Casos de Éxito</h2>
          <div class="case-cards">
            <div class="case-card">
              <img src="https://via.placeholder.com/350x200" alt="Caso de éxito 1">
              <h3>Integración de CRM con ERP</h3>
              <p>Implementamos una integración fluida entre un sistema CRM y un ERP para una empresa multinacional, mejorando la gestión de clientes y procesos internos.</p>
            </div>
            <div class="case-card">
              <img src="https://via.placeholder.com/350x200" alt="Caso de éxito 2">
              <h3>Automatización de Procesos de Facturación</h3>
              <p>Conectamos sistemas de pago y facturación, permitiendo la automatización completa del proceso de ventas y facturación, reduciendo errores y tiempos de procesamiento.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Testimonios -->
      <section class="testimonials">
        <div class="container">
          <h2>Lo que dicen nuestros clientes</h2>
          <div class="testimonial-cards">
            <div class="testimonial-card">
              <img src="https://via.placeholder.com/100x100" alt="Cliente 1">
              <p>"La integración de nuestras plataformas fue clave para mejorar la eficiencia operativa y la relación con nuestros clientes. Un trabajo excepcional." </p>
              <h4>Pedro Sánchez, Director de Tecnología en TechWare</h4>
            </div>
            <div class="testimonial-card">
              <img src="https://via.placeholder.com/100x100" alt="Cliente 2">
              <p>"Gracias a la integración de nuestras herramientas de marketing, hemos logrado automatizar procesos y enfocar nuestros esfuerzos en la estrategia."</p>
              <h4>Lucía Ríos, Gerente de Marketing en Innovatek</h4>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Footer -->
      <footer class="footer">
        <div class="container footer-content">
          <p>&copy; 2024 Metadicktyon Solutions | Todos los derechos reservados</p>
          <p>Creado por la División de Tecnología de Metadicktyon Solutions | Aprobado por el CEO</p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ITAppIntegrationPage',
  };
  </script>
  
  <style scoped>
  /* Global Styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    line-height: 1.6;
  }
  
  /* Hero Section */
  .hero {
    background-color: #1e3a5f; /* Azul oscuro */
    color: white;
    padding: 4rem 0;
    text-align: center;
  }
  
  
  .hero h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-top: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* ¿Por qué integrar apps de terceros? */
  .why-integration {
    padding: 4rem 0;
    background-color: #fff;
    text-align: center;
  }
  
  .why-integration h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #1e3a5f;
  }
  
  .benefits {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
  
  .benefit-card {
    background-color: #e5f1fb;
    padding: 2.5rem;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .benefit-card h3 {
    font-size: 1.75rem;
    color: #1e3a5f;
  }
  
  /* Cómo funciona la integración */
  .how-it-works {
    padding: 4rem 0;
    background-color: #f8f8f8;
  }
  
  .how-it-works h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #1e3a5f;
  }
  
  .steps {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .step {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 22%;
  }
  
  .step h3 {
    font-size: 1.5rem;
    color: #1e3a5f;
  }
  
  .step p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Integraciones Comunes */
  .common-integrations {
    padding: 4rem 0;
    background-color: #fff;
  }
  
  .common-integrations h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #1e3a5f;
  }
  
  .integration-cards {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .integration-card {
    background-color: #e5f1fb;
    padding: 2rem;
    border-radius: 8px;
    width: 30%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .integration-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .integration-card h3 {
    font-size: 1.75rem;
    margin-top: 1rem;
    color: #1e3a5f;
  }
  
  /* Casos de Éxito */
  .success-cases {
    padding: 4rem 0;
    background-color: #f8f8f8;
  }
  
  .success-cases h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #1e3a5f;
  }
  
  .case-cards {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .case-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    width: 48%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .case-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .case-card h3 {
    font-size: 1.75rem;
    margin-top: 1rem;
  }
  
  /* Testimonios */
  .testimonials {
    padding: 4rem 0;
    background-color: #f8f8f8;
  }
  
  .testimonials h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #1e3a5f;
  }
  
  .testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
  
  .testimonial-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .testimonial-card p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .testimonial-card h4 {
    margin-top: 1rem;
    font-size: 1rem;
    color: #1e3a5f;
  }
  
  /* Footer */
  .footer {
    padding: 2rem;
    background-color: #1e3a5f;
    color: white;
    text-align: center;
  }
  
  .footer-content p {
    font-size: 1rem;
  }
  </style>
  